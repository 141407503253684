import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import logo from "../assets/images/biai-logo-cool.png";
import { Link } from "react-router-dom";

export default function SecondaryNavBar() {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar>
          <Link to="/" relative={"path"}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="open drawer"
              sx={{ mr: 2 }}
            >
              <img
                className="nav-bar__logo"
                src={logo}
                alt="Biai logo"
                width="60"
                height="60"
              />
            </IconButton>
          </Link>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
