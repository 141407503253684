import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { dummyProducts } from "./dummyProducts";
import { dummyCategories } from "./dummyCategories";

export interface Product {
  id: string;
  title: string;
  description: string;
  resourceUrl: string;
  icon: string;
  price?: number;
  categoryId: string;
}

interface Category {
  name: string;
  id: string;
  description: string;
}

interface ProductState {
  products: Product[];
  categories: Category[];
  search: string;
  selectedCategory: Category | null;
}

const initialState: ProductState = {
  products: dummyProducts,
  categories: dummyCategories,
  search: "",
  selectedCategory: null,
};

export const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<Product[]>) => {
      state.products = action.payload;
    },
    setProductSearch: (state, action: PayloadAction<string>) => {
      state.search = action.payload;
    },
    setSelectedCategory: (state, action: PayloadAction<Category>) => {
      state.selectedCategory = action.payload;
    },
  },
});

export const { setProducts, setProductSearch, setSelectedCategory } =
  productSlice.actions;

export default productSlice.reducer;
