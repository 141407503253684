export const dummyCategories = [
  {
    name: "Celulares",
    id: "celulares",
    description: "Celulares",
  },
  {
    name: "Computadoras",
    id: "computador",
    description: "Computadoras",
  },
  {
    name: "Tablets",
    id: "tablet",
    description: "Tablets",
  },
  {
    name: "Accesorios",
    id: "accesorios",
    description: "Accesorios",
  },
  {
    name: "Reparación",
    id: "reparacion",
    description: "Reparación",
  },
  {
    name: "Diseño",
    id: "diseño",
    description: "Diseño",
  },
  {
    name: "Publicidad",
    id: "publicidad",
    description: "Publicidad",
  },
  {
    name: "Facturación",
    id: "facturacion",
    description: "Facturación",
  },
  {
    name: "Trámites",
    id: "tramites",
    description: "Trámites",
  },
];
