import React from "react";
import { NavLink } from "react-router-dom";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
interface NavBarTabProps {
  path: string;
  label: string;
}

export const NavBarTabIcon: React.FC<NavBarTabProps> = ({ path, label }) => {
  return (
    <NavLink
      to={path}
      end
      className={({ isActive }) =>
        "nav-bar__tab " + (isActive ? "nav-bar__tab--active" : "")
      }
    >
      <span style={{ marginRight: "0.5rem" }}>Carrito</span>
      <ShoppingCartIcon />
    </NavLink>
  );
};
