import React from "react";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";
import PrimarySearchAppBar from "../components/navigation-bar";
import bannerLong from "../assets/images/banner-registro-ancho.png";
import bannerShort from "../assets/images/banner-registro-short.png";
import Box from "@mui/material/Box";
import { Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import image from "../assets/images/image.png";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <MuiLink color="inherit" href="https://www.tiendasbiai.com">
        tiendasbiai.com
      </MuiLink>{" "}
      {new Date().getFullYear()}.
    </Typography>
  );
}

export function HomePage() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <PrimarySearchAppBar />
      {isMobile ? (
        <Box sx={{ padding: { xs: "0px 0px", md: "0px 200px" } }}>
          <img
            src={bannerShort}
            alt="Biai, Desarrollo web Yarumal"
            width={"100%"}
            height={"auto"}
          />
        </Box>
      ) : (
        <Box sx={{ padding: { xs: "0px 0px", md: "0px 200px" } }}>
          <img
            src={bannerLong}
            alt="Biai, Desarrollo web Yarumal"
            width={"100%"}
            height={"auto"}
          />
        </Box>
      )}
      <Container sx={{ padding: { xs: "0px 0px", md: "0px 100px" } }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {Array.from(Array(1)).map((_, index) => (
            <Grid item xs={2} sm={4} md={4} key={index}>
              <Box
                sx={{
                  backgroundColor: "white",
                  minHeight: "250px",
                  cursor: "pointer",
                }}
              >
                <Link to={"/downhill-yarumal"} relative={"path"}>
                  <img
                    src={image}
                    alt="React logo long"
                    width={"100%"}
                    height={"auto"}
                  />
                  <Box p={1} sx={{ backgroundColor: "#202c3e" }}>
                    <Stack
                      direction={"row"}
                      display={"flex"}
                      justifyContent={"space-between"}
                    >
                      <Button>
                        <span
                          style={{
                            fontSize: "1.0rem",
                            fontWeight: "bold",
                            color: "orange",
                            fontStyle: "italic",
                          }}
                        >
                          Válida Municipal, 2023
                        </span>
                      </Button>
                    </Stack>
                  </Box>
                </Link>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Copyright />
    </>
  );
}
