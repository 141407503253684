import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";

import logo from "../../../assets/images/biai-logo-cool.png";
import { useDispatch } from "react-redux";
import useDebounce from "../../../hooks/debounceHook";
import { setProductSearch } from "../../../slices/productSlice";
import SearchIcon from "@mui/icons-material/Search";
import Button from "@mui/material/Button";
export const NavBarBrand: React.FC = () => {
  return (
    <div className="nav-bar__brand">
      <NavLink to="/">
        <img
          className="nav-bar__logo"
          src={logo}
          alt="Biai logo"
          width="60"
          height="60"
        />
      </NavLink>
    </div>
  );
};

export const NavBarSearch: React.FC = () => {
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const debouncedSearch = useDebounce(search, 500);

  useEffect(() => {
    dispatch(setProductSearch(debouncedSearch));
  }, [debouncedSearch, dispatch]);

  return (
    <div className={"nav-bar__search"}>
      <Button variant={"outlined"} className={"nav-bar__search--button"}>
        <input
          className={"nav-bar__search--input"}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
          value={search}
          type="search"
          placeholder="Buscar Productos & Servicios"
        ></input>
        <SearchIcon style={{ color: "white" }} fontSize={"large"} />
      </Button>
    </div>
  );
};
