import React, { useMemo, useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import bannerLong from "../assets/images/banner-doctorbike-ancho.png";
import bannerShort from "../assets/images/banner-doctorbike-short.png";
import payment from "../assets/images/payment_method.png";
import Box from "@mui/material/Box";
import {
  Alert,
  Grid,
  Input,
  InputLabel,
  Modal,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { esES } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import ReactWhatsapp from "react-whatsapp";
import SecondaryNavBar from "../components/navigation-bar-secondary";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

const emptyFormData = {
  names: "",
  lastNames: "",
  email: "",
  phone: "",
  idNumber: "",
  emergencyPhone: "",
  socialSecurity: "",
  team: "",
  gender: "Masculino",
  bornDate: null,
  category: "",
  bloodType: "",
};

const emptyErrors = {
  names: "",
  lastNames: "",
  email: "",
  phone: "",
  idNumber: "",
  emergencyPhone: "",
  socialSecurity: "",
  team: "",
  gender: "",
  bornDate: "",
  category: "",
  bloodType: "",
};

type FormData = {
  names: string;
  lastNames: string;
  email: string;
  phone: string;
  idNumber: string;
  emergencyPhone: string;
  socialSecurity: string;
  team: string;
  gender: string;
  bornDate: Dayjs | null;
  category: string;
  bloodType: string;
};

type FormErrors = {
  [K in keyof FormData]?: string;
};

export function PublicPage() {
  const [formErrors, setFormErrors] = useState<FormErrors>(emptyErrors);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [formData, setFormData] = useState<FormData>(emptyFormData);

  const handleInputChange =
    (field: keyof FormData) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;

      // Update the formData with the current value
      setFormData((prevData) => ({
        ...prevData,
        [field]: value,
      }));

      // Re-evaluate errors for that field
      let fieldError: string | undefined;
      if (!value) {
        fieldError = "empty field";
      }

      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [field]: fieldError,
      }));
    };

  const handleSelectChange =
    (field: keyof FormData) => (event: SelectChangeEvent) => {
      setFormData({
        ...formData,
        [field]: event.target.value,
      });
      // Re-evaluate errors for that field
      let fieldError: string | undefined;
      if (!event.target.value) {
        fieldError = "empty field";
      }
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [field]: fieldError,
      }));
    };

  const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    const email = event.target.value;

    setFormData({
      ...formData,
      email,
    });

    setFormErrors({
      ...formErrors,
      email: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i.test(email)
        ? undefined
        : "Email Inválido",
    });
  };

  const handleDateChange = (date: Dayjs | null) => {
    setFormData({
      ...formData,
      bornDate: date,
    });
  };

  const handleSubmit = () => {
    let errors: FormErrors = {};
    setFormErrors(errors);

    for (const [key, value] of Object.entries(formData)) {
      if (!value) errors[key as keyof FormData] = "Este campo es requerido.";
    }

    const hasErrors = Object.values(errors).some(
      (x) => x !== undefined || x !== ""
    );
    if (hasErrors) {
      setFormErrors(errors);
      setErrorMessage("Por favor, completa todos los campos.");
    } else {
      setErrorMessage("");
      setFormErrors(emptyErrors);
    }
  };

  const message = useMemo(
    () =>
      `FORMULARIO DE INSCRIPCIÓN VÁLIDA YARUMAL. Nombres: ${
        formData.names
      }, Apellidos: ${formData.lastNames}, Documento de Identidad: ${
        formData.idNumber
      }, Celular: ${formData.phone}, Email: ${formData.email}, Género: ${
        formData.gender
      }, Fecha de Nacimiento: ${
        formData.bornDate ? formData.bornDate.format("YYYY-MM") : ""
      }, Categoría: ${formData.category}, Equipo: ${
        formData.team
      }, Seguridad Social: ${formData.socialSecurity}, Tipo de Sangre: ${
        formData.bloodType
      }, Teléfono de Emergencia: ${formData.emergencyPhone}`,
    [
      formData.names,
      formData.lastNames,
      formData.idNumber,
      formData.phone,
      formData.email,
      formData.gender,
      formData.bornDate,
      formData.category,
      formData.team,
      formData.socialSecurity,
      formData.bloodType,
      formData.emergencyPhone,
    ]
  );

  const isFormValid = useMemo(() => {
    return Object.values(formData).every((x) => !!x);
  }, [formData]);

  return (
    <>
      <SecondaryNavBar />
      <Banner />
      <Container sx={{ padding: { xs: "0px 20px", md: "0px 120px" } }}>
        <Box textAlign="justify">
          <DownHillYarumal />
          <PaymentProcessDescription />
          <InscriptionFormDescription />
        </Box>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          sx={{ width: "100%", borderRadius: "10px" }}
          justifyContent={"center"}
          alignItems={"center"}
        >
          <Grid item xs={2} sm={4} md={6} key={"nombres"}>
            <Input
              name={"name"}
              placeholder={"Nombres"}
              sx={{ width: "100%" }}
              onChange={handleInputChange("names")}
              value={formData.names}
              error={!!formErrors.names}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={6} key={"apellidos"}>
            <Input
              name={"last name"}
              placeholder={"Apellidos"}
              sx={{ width: "100%" }}
              onChange={handleInputChange("lastNames")}
              value={formData.lastNames}
              error={!!formErrors.lastNames}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={6} key={"id-number"}>
            <Input
              name={"id number"}
              placeholder={"Número de Identificación"}
              sx={{ width: "100%" }}
              onChange={handleInputChange("idNumber")}
              value={formData.idNumber}
              error={!!formErrors.idNumber}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={6} key={"gender"}>
            <InputLabel id="gender-simple-select-label">Género</InputLabel>
            <Select
              name="gender"
              labelId="gender-simple-select-label"
              id="select-gender"
              value={formData.gender}
              label="Masculino"
              onChange={handleSelectChange("gender")}
              sx={{ width: "100%" }}
              error={!!formErrors.gender}
            >
              <MenuItem value={"Masculino"}>Masculino</MenuItem>
              <MenuItem value={"Femenino"}>Femenino</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={2} sm={4} md={6} key={"phone"}>
            <Input
              name={"phone"}
              placeholder={"Teléfono Celular"}
              sx={{ width: "100%" }}
              onChange={handleInputChange("phone")}
              value={formData.phone}
              error={!!formErrors.phone}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={6} key={"email"}>
            <Input
              name={"email"}
              placeholder={"Email"}
              sx={{ width: "100%" }}
              onChange={handleEmail}
              value={formData.email}
              type={"email"}
              error={!!formErrors.email}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={6} key={"fecha-nacimiento"}>
            <LocalizationProvider
              dateAdapter={AdapterDayjs}
              localeText={
                esES.components.MuiLocalizationProvider.defaultProps.localeText
              }
            >
              <DemoContainer components={["DateField", "DateField"]}>
                <DatePicker
                  label="Fecha de nacimiento"
                  value={formData.bornDate}
                  onChange={handleDateChange}
                  sx={{ width: "100%" }}
                  views={["year", "month"]}
                />
              </DemoContainer>
            </LocalizationProvider>
          </Grid>

          <Grid item xs={2} sm={4} md={6} key={"Categoría"}>
            <InputLabel id="demo-simple-select-label">Categoría</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="category"
              value={formData.category}
              label="Category"
              onChange={handleSelectChange("category")}
              sx={{ width: "100%" }}
              error={!!formErrors.category}
            >
              <MenuItem value={"Cadetes"}>Cadetes 10 a 15 años</MenuItem>
              <MenuItem value={"Rígida Libre"}>Rígida</MenuItem>
              <MenuItem value={"Junior"}>Junior 16 a 18 años</MenuItem>
              <MenuItem value={"Máster A"}>Máster A, 30 a 35 años</MenuItem>
              <MenuItem value={"Máster B"}>Máster B, 36 años o más</MenuItem>
              <MenuItem value={"Amateur Libre"}>Amateur Libre</MenuItem>
              <MenuItem value={"Pro Libre"}>Pro o Senior Libre</MenuItem>
              <MenuItem value={"Élite Libre"}>Élite Libre</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={2} sm={4} md={6} key={"team"}>
            <Input
              name={"team"}
              placeholder={"Team"}
              sx={{ width: "100%" }}
              onChange={handleInputChange("team")}
              value={formData.team}
              error={!!formErrors.team}
            />
          </Grid>
          <Grid item xs={2} sm={4} md={6} key={"social-security"}>
            <Input
              name={"social-security"}
              placeholder={"Seguridad Social"}
              sx={{ width: "100%" }}
              onChange={handleInputChange("socialSecurity")}
              value={formData.socialSecurity}
              error={!!formErrors.socialSecurity}
            />
          </Grid>

          <Grid item xs={2} sm={4} md={6} key={"blood-type"}>
            <InputLabel id="blood-type-simple-select-label">
              Tipo de Sangre
            </InputLabel>
            <Select
              labelId="blood-type-simple-select-label"
              id="select-blood-type"
              value={formData.bloodType}
              label="Tipo de Sangre"
              onChange={handleSelectChange("bloodType")}
              sx={{ width: "100%" }}
              error={!!formErrors.bloodType}
            >
              <MenuItem value={"A+"}>A+</MenuItem>
              <MenuItem value={"A-"}>A-</MenuItem>
              <MenuItem value={"O+"}>O+</MenuItem>
              <MenuItem value={"O-"}>O-</MenuItem>
              <MenuItem value={"AB-"}>AB-</MenuItem>
              <MenuItem value={"B+"}>B+</MenuItem>
              <MenuItem value={"B-"}>B-</MenuItem>:w
            </Select>
          </Grid>

          <Grid item xs={2} sm={4} md={6} key={"emergency-phone"}>
            <Input
              name={"emergency-phone"}
              placeholder={"Contacto de Emergencia"}
              sx={{ width: "100%" }}
              onChange={handleInputChange("emergencyPhone")}
              value={formData.emergencyPhone}
              error={!!formErrors.emergencyPhone}
            />
          </Grid>
        </Grid>
        {errorMessage && !isFormValid ? (
          <Alert severity="error">{errorMessage}</Alert>
        ) : (
          <Alert severity={"info"}>Ya casi estamos listos!</Alert>
        )}
        {isFormValid ? (
          <WhatsAppFormMessage message={message} />
        ) : (
          <Button
            sx={{
              width: "100%",
              marginTop: "10px",
              backgroundColor: "#00BF63",
              height: "50px",
            }}
            variant={"contained"}
            color={"secondary"}
            onClick={handleSubmit}
          >
            {`Enviar mi inscripción + Soporte de pago`}
          </Button>
        )}

        <hr />
        <Box textAlign={"center"}>Organiza: DoctorBike</Box>
      </Container>
      <Copyright />
    </>
  );
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function BasicModal({
  open,
  handleClose,
  handleOpen,
  message,
}: {
  open: boolean;
  handleClose: any;
  handleOpen: any;
  message: string;
}) {
  return (
    <div>
      <Button onClick={handleOpen}>Open modal</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>hello world</Box>
      </Modal>
    </div>
  );
}

const Banner = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Box>
      {isMobile ? (
        <Box sx={{ padding: { xs: "0px 0px", md: "0px 200px" } }}>
          <img
            src={bannerShort}
            alt="Evento de Downhill Yarumal, pista La Cruz"
            width={"100%"}
            height={"auto"}
          />
        </Box>
      ) : (
        <Box sx={{ padding: { xs: "0px 0px", md: "0px 100px" } }}>
          <img
            src={bannerLong}
            alt="Evento de Downhill Yarumal, pista la Cruz"
            width={"100%"}
            height={"auto"}
          />
        </Box>
      )}
    </Box>
  );
};

const DownHillYarumal = () => {
  return (
    <>
      <h1>Downhill Yarumal</h1>
      <h2>¡Hola Rider Bienvenido!</h2>
      <p>
        ¡Te espera una emocionante experiencia de downhill en Yarumal! El cierre
        de la pista será a las 09:00 a.m, así que prepárate!. Lleva tu equipo de
        seguridad y respeta las normas. ¡Disfruta al máximo y a rodar con
        adrenalina!
      </p>
    </>
  );
};

const PaymentProcessDescription = () => {
  return (
    <>
      <h3>Proceso de pago</h3>
      <img
        src={payment}
        alt="React logo long"
        width={"200px"}
        height={"auto"}
        style={{
          float: "right",
          marginLeft: "10px",
          marginBottom: "10px",
        }}
      />
      <p>
        El valor de la inscripción es de 70.000 pesos, utiliza la app
        Bancolombia o el cajero automático para realizar el pago de la
        inscripción y descarga el soporte de pago. <br />
      </p>
    </>
  );
};

const InscriptionFormDescription = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <>
      {!isMobile && (
        <Alert>
          En caso de presentar inconvenientes contactar a DoctorBike al número
          321 556 5016 o escríbenos a contacto@tiendasbiai.com.
        </Alert>
      )}
      <h3>Inscripción</h3>
      <p> Ingresa todos los datos solicitados a continuación:</p>
      {isMobile && (
        <Alert>
          En caso de presentar inconvenientes contactar a DoctorBike al número
          321 556 5016.
        </Alert>
      )}
    </>
  );
};

const WhatsAppFormMessage = ({ message }: { message: string }) => {
  return (
    <Button
      sx={{
        width: "100%",
        marginTop: "10px",
        backgroundColor: "#00BF63",
        height: "50px",
      }}
      variant={"contained"}
      color={"secondary"}
    >
      <ReactWhatsapp
        element={"span"}
        number={"57-321-556-5016"}
        message={message}
        style={{ width: "100%" }}
      >
        {`Enviar inscripción + Soporte de pago`}
        <WhatsAppIcon
          fontSize={"small"}
          style={{ color: "white", marginLeft: "5px" }}
        />
      </ReactWhatsapp>
    </Button>
  );
};

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {"Copyright © "}
      <Link color="inherit" href="https://tiendasbiai.com">
        tiendasbiai.com
      </Link>{" "}
      {new Date().getFullYear()}.
    </Typography>
  );
}
